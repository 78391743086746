import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
  mergeMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import {
  setDialogueGraphData,
  saveDialogueGraphDataFulfilled,
  publishDialogueGraphDataFulfilled,
  setActionNodeTemplate,
  setDialogueGraphs,
  setDialogueUrls,
  createDialogueGraph,
  revertDialogueGraphDataFulfilled,
  fetchDialogueGraph,
  setGraphId,
  setDialogueGraphVariables,
  fetchDialogueGraphVariables,
  setDialogueGraphBlueprints,
  deleteDialogueGraphsFulfilled,
  saveDialogueGraphInfoFulfilled,
  setDialogueGraphChannel,
  clearGraphHistory,
  setDialogueGraphInfo,
  setProactiveMessage,
  setEedNotificationData,
  saveProactiveMessageFulfilled,
  saveEedNotificationDataFulfilled,
  trainDialogueGraphDataFulfilled,
  publishDialogueGraphError,
  saveDialogueGraphError,
  setDialogueGraphPublishedAt,
  setDialogueGraphDataPublishedStatus,
  setGraphLockStatus,
  graphLockError,
  setGraphLockUuid,
  setDialogueGraphVersions,
  restoreDialogueGraphVersionFulfilled,
  restoreDialogueGraphVersionError,
  setGraphErrors,
} from "../actions/dialogues";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { isEdge, isNode } from "../util";
import getConfig from "../../lib/config";
import { forceRefresh } from "../actions/common";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
  showInfoSnackbar,
} from "../actions/snackbar";
import * as actions from "../actions";
import { setJumpGraphMappings } from "../actions/jumpGraphs";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

const defaultChannel = "web";

const channelList =
  "web,voice,google_business_messages,whatsapp,facebook,emails";

export const fetchDialoguesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH),
    debounceTime(300),
    switchMap((action) => {
      console.log("Epic: Fetching dialogue graph...");

      const graphId = state$.value.dialogueGraphId;
      const language = state$.value.language;

      const acquireLock = action.acquire_lock || "false";

      // Get lock_uuid from state or session storage as fallback
      const lockUuid =
        state$.value.graphLockUuid ||
        (typeof window !== "undefined"
          ? sessionStorage.getItem("lockUuid")
          : "") ||
        "";

      // Construct query parameters
      const acquireLockParam = `&acquire_lock=${acquireLock}`;
      const lockUuidParam = `&lock_uuid=${lockUuid}`;

      // Get tenant from state with fallbacks
      const tenant =
        state$.value.tenant ||
        (typeof window !== "undefined"
          ? localStorage.getItem("tenant")
          : "default") ||
        "default";

      console.log(
        `Fetching graph ${graphId} with acquire_lock=${acquireLock} and lock_uuid=${lockUuid}`
      );
      console.log(`Using tenant: ${tenant}`);

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${graphId}?language=${language}${acquireLockParam}${lockUuidParam}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          const { graph_data, lock_info } = response.response;
          let latest_data = graph_data?.latest_data
            ? JSON.parse(graph_data.latest_data)
            : null;
          let info_data = graph_data;
          let channel = graph_data?.channel;
          let published_change_at = graph_data?.published_change_at;
          let latest_change_at = graph_data?.latest_change_at;
          let publishedState = "unpublished";
          if (published_change_at >= latest_change_at) {
            publishedState = "success";
          }
          const actions = [];
          console.log("response", response);

          // Handle lock info first if needed
          if (lock_info && acquireLock === "true") {
            if (lock_info.status === "LOCK_ACQUIRED" && lock_info.lock_uuid) {
              // Store the lock_uuid in sessionStorage for persistent access
              if (typeof window !== "undefined") {
                sessionStorage.setItem("lockUuid", lock_info.lock_uuid);
              }
              actions.push(setGraphLockStatus(lock_info));
              actions.push(setGraphLockUuid(lock_info.lock_uuid));
            }
          }

          // Only set graph data if we have valid data
          if (latest_data?.nodes || latest_data?.links) {
            const newData = latest_data.nodes.concat(latest_data.links);
            actions.push(clearGraphHistory());
            actions.push(fetchDialogueGraphVariables());
            actions.push(setDialogueGraphData(newData));
            actions.push(setDialogueGraphInfo(info_data));
            actions.push(setDialogueGraphChannel(channel));
            actions.push(setDialogueGraphPublishedAt(published_change_at));
            actions.push(setDialogueGraphDataPublishedStatus(publishedState));
          } else {
            actions.push(clearGraphHistory());
            actions.push(setDialogueGraphData([]));
          }

          // Only fetch variables if we have valid data

          return of(...actions);
        }),
        catchError((error) => {
          console.error("Error in fetchDialoguesEpic:", error);
          if (error.status === 409) {
            const lockInfo = error.response.lock_info;
            console.log("Received 409 conflict, lock_info:", lockInfo);
            console.log(
              "Response headers:",
              error.xhr?.getAllResponseHeaders()
            );

            // Explicitly check for GRAPH_BUSY_SAME_OWNER status
            if (lockInfo && lockInfo.status === "GRAPH_BUSY_SAME_OWNER") {
              console.log(
                "Detected GRAPH_BUSY_SAME_OWNER status, showing take over dialog"
              );

              // This will trigger the useEffect in the page component that shows the dialog
              return of(setGraphLockStatus(lockInfo));
            } else {
              console.log(
                "Standard GRAPH_BUSY case, redirecting to graphs list"
              );
              return of(
                graphLockError(lockInfo),
                showErrorSnackbar(
                  "Graph is currently being edited by another user"
                )
              );
            }
          }
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const createDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_DIALOGUE_GRAPH),
    mergeMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs?graph_label=dev_${new Date().getTime()}&language=${
          state$.value.language
        }&channel=${action.channel || defaultChannel}`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        mergeMap((response) => {
          const createdId = JSON.parse(response?.response?.graph_id);

          // Always dispatch setGraphId and setDialogueGraphPublishedAt
          const actionsToDispatch = [
            setGraphId(createdId),
            setDialogueGraphPublishedAt(null),
          ];

          // If name is provided, dispatch saveDialogueGraphInfo with the name
          if (action.name) {
            actionsToDispatch.push(
              actions.dialogues.saveDialogueGraphInfo(createdId, {
                latest_label: action.name,
                published_label: action.name,
              })
            );
          }

          // Fetch the updated list of dialogue graphs
          actionsToDispatch.push(
            actions.dialogues.fetchDialogueGraphs(action.channel || "web")
          );

          return actionsToDispatch;
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const createFormDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_FORM_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/form_graphs?language=${
          state$.value.language
        }&channel=${action.channel || defaultChannel}
                &form_id=${
                  action.form_id || defaultChannel
                }&dialogue_graph_id=${action.dialogue_graph_id || null}`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      let createdId = JSON.parse(response?.response?.graph_id);
      return setGraphId(createdId);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const copyDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.COPY_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax.post(
        `${REACT_APP_ACTION_HOST}/graphs/${action.sourceId}/copy?language=${state$.value.language}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        }
      );
    }),
    map((response) => {
      let createdId = JSON.parse(response?.response?.graph_id);
      return setGraphId(createdId);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const copyDialogueGraphTemplateEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.COPY_DIALOGUE_GRAPH_TEMPLATE),
    mergeMap((action) => {
      return ajax
        .post(
          `${REACT_APP_ACTION_HOST}/graphs/templates/${action.sourceId}/copy?language=${state$.value.language}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          mergeMap((response) => {
            const createdId = JSON.parse(response?.response?.graph_id);

            // Always dispatch setGraphId
            const actionsToDispatch = [setGraphId(createdId)];

            // Conditionally dispatch saveDialogueGraphInfo only if data is present
            if (action.data) {
              actionsToDispatch.push(
                actions.dialogues.saveDialogueGraphInfo(createdId, action.data)
              );
            }

            actionsToDispatch.push(
              actions.dialogues.fetchDialogueGraphs(
                action?.data?.channel || "web"
              )
            );
            actionsToDispatch.push(
              actions.dialogues.publishDialogueGraphData()
            );

            return actionsToDispatch;
          }),
          catchError((error) => of(errorMessage(error.message)))
        );
    })
  );

export const deleteDialogueGraphsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.DELETE_DIALOGUE_GRAPHS),
    switchMap((action) =>
      ajax
        .delete(`${REACT_APP_ACTION_HOST}/graphs/${action.graphIds}`, {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          concatMap((response) => {
            let status = response.status === 200 ? "success" : "error";
            return of(
              deleteDialogueGraphsFulfilled(status),
              setDialogueGraphs(
                state$.value.dialogueGraphs.filter(
                  (graph) => !action.graphIds.includes(graph.id)
                )
              )
            );
          }),
          catchError((error) => of(errorMessage(error.message)))
        )
    )
  );

export const fetchAllDialoguesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPHS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/info?languages=${
          state$.value.language
        }&channels=${action.channel || channelList}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueGraphs(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchAllUrlsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_URLS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/urls/info?languages=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueUrls(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchAllDialogueBlueprintsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH_BLUEPRINTS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/templates/info?languages=${
          state$.value.language
        }&created_by=SYSTEM&channels=${action.channel || defaultChannel}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueGraphBlueprints(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const saveDialogueGraphData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_DIALOGUE_GRAPH),
    debounceTime(200),
    switchMap((action) => {
      console.log(
        "Saving dialogue graph data",
        state$.value.dialogueGraphData.present
      );
      console.log("Graph lock UUID:", state$.value.graphLockUuid);
      let formattedGraphData = {
        directed: true,
        multigraph: true,
        graph: {},
        nodes: state$.value.dialogueGraphData.present.filter((el) =>
          isNode(el)
        ),
        links: state$.value.dialogueGraphData.present.filter((el) =>
          isEdge(el)
        ),
      };

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}?language=${state$.value.language}&lock_uuid=${state$.value.graphLockUuid}&train=true`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
        body: {
          latest_data: JSON.stringify(formattedGraphData),
        },
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          let draftId = response?.response?.draft_id;
          let latestChangeAt = response?.response?.latest_change_at;

          let actions = [
            saveDialogueGraphDataFulfilled(status),
            fetchDialogueGraphVariables(),
          ];

          if (draftId && state$.value.dialogueGraphInfo?.draft_id !== draftId) {
            actions.push(
              setDialogueGraphInfo({
                ...(state$.value.dialogueGraphInfo || {}),
                draft_id: state$.value.dialogueGraphInfo?.draft_id || draftId,
              })
            );
          }
          if (latestChangeAt) {
            actions.push(
              setDialogueGraphInfo({
                ...(state$.value.dialogueGraphInfo || {}),
                latest_change_at: latestChangeAt,
              })
            );
            actions.push(
              setDialogueGraphs(
                state$.value.dialogueGraphs.map((e) => {
                  if (e.id === state$.value.dialogueGraphInfo.id)
                    return { ...e, latest_change_at: latestChangeAt };
                  return e;
                })
              )
            );
          }

          return of(...actions);
        }),
        catchError((error) =>
          of(
            saveDialogueGraphError(error.message),
            saveDialogueGraphDataFulfilled("error")
          )
        )
      );
    })
  );

export const saveDialogueGraphInfo = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_DIALOGUE_GRAPH_INFO),
    debounceTime(2000),
    switchMap((action) => {
      // Merge existing info with new payload
      const mergedPayload = {
        ...(state$.value.dialogueGraphInfo || {}),
        ...action.payload,
      };

      console.log(
        "saveDialogueGraphInfo epic - Starting API call with payload:",
        action.payload
      );
      console.log("saveDialogueGraphInfo epic - Graph ID:", action.graphId);

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${action.graphId}?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          console.log(
            "saveDialogueGraphInfo epic - API response status:",
            response.status
          );
          console.log(
            "saveDialogueGraphInfo epic - API response:",
            response.response
          );
          console.log(
            "saveDialogueGraphInfo epic - Setting status to:",
            status
          );

          return of(
            saveDialogueGraphInfoFulfilled(status),
            setDialogueGraphInfo(mergedPayload),
            setDialogueGraphs(
              state$.value.dialogueGraphs.map((e) => {
                if (e.id === action.graphId) return { ...e, ...mergedPayload };
                return e;
              })
            )
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const publishDialogueGraphData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.PUBLISH_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/publish?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
        body: {
          comment: action.comment || "",
        },
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          const published_at = response?.response?.published_at;
          return of(
            publishDialogueGraphDataFulfilled(status),
            setDialogueGraphPublishedAt(published_at),
            showSuccessSnackbar("Snackbars.publishSuccess")
          );
        }),
        catchError((error) =>
          of(
            publishDialogueGraphError(error.message),
            showErrorSnackbar("Snackbars.publishError")
          )
        )
      );
    })
  );

export const trainDialogueGraphData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.TRAIN_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/train?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        map((response) => {
          let status = response.status === 200 ? "success" : "error";
          return trainDialogueGraphDataFulfilled(status);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const revertDialogueGraphData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.REVERT_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/revert?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      });
    }),
    concatMap((response) => {
      let status = response.status === 200 ? "success" : "error";

      return of(revertDialogueGraphDataFulfilled(status));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchDialogueGraphVersions = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH_VERSIONS),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/versions?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        map((response) => {
          return setDialogueGraphVersions(response.response.versions);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const restoreDialogueGraphVersion = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.RESTORE_DIALOGUE_GRAPH_VERSION),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/revert?version_id=${action.versionId}&language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          return of(
            restoreDialogueGraphVersionFulfilled(status),
            fetchDialogueGraph(null, "false"),
            showSuccessSnackbar("Snackbars.restoreVersionSuccess")
          );
        }),
        catchError((error) =>
          of(
            restoreDialogueGraphVersionError(error.message),
            showErrorSnackbar("Snackbars.restoreVersionError")
          )
        )
      );
    })
  );

export const fetchActionNodeTemplate = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_ACTION_NODE_TEMPLATE),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/action/${getActionNameForVariant(
          action.nodeType,
          action.nodeVariant
        )}/node?language=${state$.value.language}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        map((response) => {
          return setActionNodeTemplate(response.response);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

const getActionNameForVariant = (type, variant) => {
  return `dialogue_editor_${variant}_form`;
};

export const clearDialogueGraphEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.CLEAR_DIALOGUE_GRAPH),
    map(() => {
      return setDialogueGraphData([]);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const clearDialogueGraphsEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.CLEAR_DIALOGUE_GRAPHS),
    map(() => {
      return setDialogueGraphData([]);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const refetchDialogueGraphEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.REVERT_DIALOGUE_GRAPH_FULFILLED),
    map(() => {
      return fetchDialogueGraph(null, "false");
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchDialogueVariablesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH_VARIABLES),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/variables?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/gzip",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      let variables = response?.response?.result;
      return setDialogueGraphVariables(variables);
    }),
    catchError((error) => {
      if (error.status === 404) {
        return of(setDialogueGraphVariables([]));
      }
      return of(errorMessage(error.message));
    })
  );

export const fetchProactiveMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_PROACTIVE_MESSAGE),
    debounceTime(100),
    switchMap((action) => {
      console.log("fetchProactiveMessage epic - Starting API call");
      console.log(
        "fetchProactiveMessage epic - Graph ID:",
        state$.value.dialogueGraphId
      );

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/proactive_message`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setProactiveMessage(response?.response);
        }),
        catchError((error) => {
          console.error("fetchProactiveMessage epic - API error:", error);
          console.error(
            "fetchProactiveMessage epic - Error status:",
            error.status
          );
          console.error(
            "fetchProactiveMessage epic - Error response:",
            error.response
          );
          return of(
            setProactiveMessage({ active: false }),
            errorMessage(error.message)
          );
        })
      );
    })
  );

export const saveProactiveMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_PROACTIVE_MESSAGE),
    debounceTime(100),
    switchMap((action) => {
      console.log(
        "saveProactiveMessageEpic - Starting API call with payload:",
        action.payload
      );
      console.log(
        "saveProactiveMessageEpic - Graph ID:",
        state$.value.dialogueGraphId
      );

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/proactive_message`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        map((response) => {
          return saveProactiveMessageFulfilled();
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const saveEedNotificationData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_EED_NOTIFICATION_DATA),
    debounceTime(100),
    switchMap((action) => {
      console.log(
        "saveEedNotificationData epic - Starting API call with payload:",
        action.payload
      );
      console.log(
        "saveEedNotificationData epic - Graph ID:",
        state$.value.dialogueGraphId
      );

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/notifications`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        map((response) => {
          return saveEedNotificationDataFulfilled();
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const fetchEedNotificationData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_EED_NOTIFICATION_DATA),
    debounceTime(100),
    switchMap((action) => {
      console.log("fetchEedNotificationData epic - Starting API call");
      console.log(
        "fetchEedNotificationData epic - Graph ID:",
        state$.value.dialogueGraphId
      );

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/notifications`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setEedNotificationData(response?.response);
        }),
        catchError((error) => {
          console.error("fetchEedNotificationData epic - API error:", error);
          console.error(
            "fetchEedNotificationData epic - Error status:",
            error.status
          );
          console.error(
            "fetchEedNotificationData epic - Error response:",
            error.response
          );
          return of(
            setEedNotificationData({ day_of_month: 1 }),
            errorMessage(error.message)
          );
        })
      );
    })
  );

export const setupAutoDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SETUP_AUTO_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/setup?should_publish=${action.payload.publish}&language=${state$.value.language}&channel=${action.payload.channel}&generate_faq_graph=${action.payload.generate_faq_graph}&generate_ticket_graph=${action.payload.generate_ticket_graph}&main_label=${action.payload.main_label}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload.body,
      }).pipe(
        concatMap((response) => {
          let createdId = JSON.parse(response?.response?.graph_id);
          return of(forceRefresh(), setGraphId(createdId));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const releaseGraphLockEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.RELEASE_GRAPH_LOCK),
    switchMap((action) => {
      const lockUuid = state$.value.graphLockUuid;
      console.log("[releaseGraphLockEpic] Releasing lock UUID:", lockUuid);
      if (!lockUuid) return of();

      // Use tenant from Redux with fallbacks
      const tenant =
        state$.value.tenant ||
        (typeof window !== "undefined" ? localStorage.getItem("tenant") : "") ||
        "default";

      console.log("[releaseGraphLockEpic] Using tenant:", tenant);

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/lock/${lockUuid}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: tenant,
        },
      }).pipe(
        map(() => {
          // Clear from sessionStorage
          if (typeof window !== "undefined") {
            sessionStorage.removeItem("lockUuid");
          }
          return setGraphLockUuid(null);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const renewGraphLockEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.RENEW_GRAPH_LOCK),
    switchMap((action) => {
      const lockUuid = state$.value.graphLockUuid;
      console.log("[renewGraphLockEpic] Renewing lock UUID:", lockUuid);
      if (!lockUuid) return of();

      // Use tenant from Redux with fallbacks
      const tenant =
        state$.value.tenant ||
        (typeof window !== "undefined" ? localStorage.getItem("tenant") : "") ||
        "default";

      console.log("[renewGraphLockEpic] Using tenant:", tenant);

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/lock/${lockUuid}/renew`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: tenant,
        },
      }).pipe(
        map((response) => {
          const { lock_info } = response.response;
          console.log(
            "[renewGraphLockEpic] Renewal response lock_info:",
            lock_info
          );
          if (lock_info?.status === "LOCK_ACQUIRED") {
            return setGraphLockStatus(lock_info);
          }
          return { type: "NOOP" };
        }),
        catchError((error) => {
          console.error("[renewGraphLockEpic] Error renewing lock:", error);

          return of(setGraphLockUuid(null), errorMessage(error.message));
        })
      );
    })
  );

export const releaseGraphLockBeaconEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.RELEASE_GRAPH_LOCK_BEACON),
    switchMap((action) => {
      const { graphId, lockUuid, token, tenant } = action.payload;

      console.log(
        "[releaseGraphLockBeaconEpic] Releasing lock UUID:",
        lockUuid
      );
      console.log("[releaseGraphLockBeaconEpic] Using tenant:", tenant);

      // Get a fallback tenant from state if not provided in payload
      const effectiveTenant =
        tenant ||
        state$.value.tenant ||
        (typeof window !== "undefined" ? localStorage.getItem("tenant") : "") ||
        "default";

      console.log(
        "[releaseGraphLockBeaconEpic] Effective tenant:",
        effectiveTenant
      );

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${graphId}/delete-lock/${lockUuid}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Tenant_Realm: effectiveTenant,
        },
      }).pipe(
        concatMap(() => {
          if (typeof window !== "undefined") {
            sessionStorage.removeItem("lockUuid");
          }
          return of(setGraphLockStatus(null), setGraphLockUuid(null));
        }),
        catchError((error) => {
          console.error("Error in releaseGraphLockBeacon:", error);
          if (error.status === 409) {
            return of(
              graphLockError(error.response.lock_info),
              showErrorSnackbar(
                "Graph is currently being edited by another user"
              )
            );
          }
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const fetchGraphErrorsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_GRAPH_ERRORS),
    debounceTime(500),
    switchMap((action) => {
      const graphId = state$.value.dialogueGraphId;
      const language = state$.value.language;
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${graphId}/errors?language=${language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      console.log(response);
      const result = response.response["result"];
      return of(setGraphErrors(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
